import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import MeetOurTeam from "./about/MeetOurTeam"
import OurOffice from "./about/OurOffice"
import ReasonsWhy from "./about/ReasonsWhy"
import LearnMoreCTA from "./about/LearnMoreCTA"
import OverlappedImageText from "./about/OverlappedImageText"
import { getPostObj } from "../utils/utils"

const AboutUsPage = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  const { post, language } = postObj

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />
        <div className="joshua-tree-content about-us">
          <TopSection post={post} language={language} />
          <OurMission post={post} language={language} sideColumnIs={4} />
          <MeetOurDoctors post={post} language={language} colorBack={false} />
          <ReasonsWhy post={post} language={language} />
          {post.textOverlappedImages.hasThisSection && (
            <OverlappedImageText items={post.textOverlappedImages.items} />
          )}
          <MeetOurTeam post={post} language={language} colorBack />
          <OurOffice post={post} language={language} />
          <LearnMoreCTA post={post} language={language} colorBack/>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
        }
        ourOffice {
          imageId
          text
          imageIdMobile
          locationCopy
          hasThisSection
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          useImageStrip
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
          imageStrip {
            youtube
            images {
              imageId
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        textOverlappedImages {
          hasThisSection
          items {
            isReversed
            heading
            blurb
            imageId
            button {
              destination
              appearance
              buttonText
              href
            }
          }
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          hasThisSection
          text
          showStaff
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
        }
        ourOffice {
          hasThisSection
          imageId
          imageIdMobile
          text
        }
        reasonsWhySection {
          hasThisSection
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          hasThisSection
          heading
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
        }
        meetOurDoctors {
          hasThisSection
          useButtons
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        title
        mainVideo {
          imageId
          youtube
          imageIdMobile
        }
        learnMore {
          blurb
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
        }
      }
    }
  }
`

export default AboutUsPage
